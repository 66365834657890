<template>
	<b-card no-body>
		<b-tabs card>
			<b-tab title="Primary Information" active>
				<b-card-text>
					<b-form-group label="Source" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.company.name }}
					</b-form-group>

					<b-form-group label="Destination" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.connectedCompany.name }}
					</b-form-group>

					<b-form-group label="Connection Types" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.connections.join(", ") }}
					</b-form-group>

					<b-form-group label="Notes" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="truncate-text">
							<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
								type="html" :text="row.item.notes" />
						</span>
					</b-form-group>

					<b-form-group label="Date Connected" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateCreated) }}
					</b-form-group>

					<b-form-group label="Duration (in Days)" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">
							{{ getConnectionDuration(row.item.dateCreated) }}
						</span>
					</b-form-group>

					<b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateCreated) }}
					</b-form-group>

					<b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateUpdated) }}
					</b-form-group>

					<b-form-group label="Status" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span v-if="row.item.isActive === 'true'">
							<b-badge variant="success" class="badgeFont">Active</b-badge>
						</span>
						<span v-else>
							<b-badge variant="secondary" class="badgeFont">Inactive</b-badge>
						</span>
					</b-form-group>
				</b-card-text>
			</b-tab>
			<b-tab title="Connected Locations">
				<b-row class="mb-2">
					<b-col sm="3" class="text-sm-center">
						<b>Name</b>
					</b-col>
					<b-col sm="3" class="text-sm-center">
						<b>Address</b>
					</b-col>
					<b-col sm="3" class="text-sm-center">
						<b>Geolocation</b>
					</b-col>
					<b-col sm="3" class="text-sm-center">
						<b>Status</b>
					</b-col>
				</b-row>
				<b-row class="mb-2" v-bind:key="location.id" v-for="location in Object.values(row.item.storageLocations)">
					<b-col sm="3" class="text-sm-center">{{ location.name }}</b-col>
					<b-col sm="3" class="text-sm-center">{{ location.address }}</b-col>
					<b-col sm="3" class="text-sm-center numFont">{{
						getCoordinates(location.geoaddress)
					}}</b-col>
					<b-col sm="3" class="text-sm-center" v-if="location.isIncluded === 'true'">
						<b-badge variant="primary">Connected</b-badge>
					</b-col>
					<b-col sm="3" class="text-sm-center" v-else>
						<b-badge variant="secondary">Not Connected</b-badge>
					</b-col>
				</b-row>
			</b-tab>
			<b-tab title="Change Logs">
				<ConnectionChangeLogsDetailsView :row="row" />
			</b-tab>
		</b-tabs>
	</b-card>
</template>

<script>
// Components
import ConnectionChangeLogsDetailsView from './ConnectionChangeLogsDetailsView.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { LocationUtil } from '@/utils/locationUtil';

// Others
import truncate from 'vue-truncate-collapsed';

export default {
	name: 'connection-details-view',
	components: {
		ConnectionChangeLogsDetailsView,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	methods: {
		getCoordinates(geoaddress) {
			geoaddress = LocationUtil.getGeoaddress(geoaddress);

			return (
				'(' +
				geoaddress.latitude.toFixed(6) +
				', ' +
				geoaddress.longitude.toFixed(6) +
				')'
			);
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getConnectionDuration(date) {
			return DateUtil.getNoOfDays(date, new Date());
		},
	},
};
</script>