<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">CHANGE LOGS</span>
                <div class="mb-2 details-view-subtitle">Recent update logs created by {{ isSuperAdmin ? 'all users' :
                    loggedUser.id }}</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="changeLogs" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(dateUpdated)="row">
                <div>
                    {{ getFormattedDateWithTime(row.item.new.dateUpdated) }}
                </div>
            </template>
            <template v-slot:cell(changes)="row">
                <div class="changed-section" v-if="hasChangesInConnections(row.item)">
                    <b>Connections</b>
                    <div v-show="getAddedConnections(row.item).length > 0">
                        Added <b><span class="new-value">{{ getAddedConnections(row.item) }}</span></b>
                    </div>
                    <div v-show="getRemovedConnections(row.item).length > 0">
                        Removed <b><span class="old-value">{{ getRemovedConnections(row.item) }}</span></b>
                    </div>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'notes')">
                    <b>Notes</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'notes') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'notes') }} </b>
                </div>
                <div class="changed-section" v-if="hasConnectedStorageLocationChanged(row.item)">
                    <b>Storage Locations</b>
                    <br>
                    <div v-for="(location, index) in getStorageLocationsArray(row.item.new.storageLocations)"
                        :key="index">
                        <p v-if="location.isIncluded === 'true'"><b><span class="new-value">{{ location.name
                                    }}</span></b>
                            is connected</p>
                        <p v-if="location.isIncluded === 'false'"><b><span class="old-value">{{ location.name
                                    }}</span></b>
                            is not connected</p>
                    </div>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'isActive')">
                    <b>Status</b>
                    <br>
                    From <span v-if="row.item.old !== null">
                        <ConnectionStatus :connection="row.item.old" />
                    </span>
                    <span v-else>&nbsp;-&nbsp;</span>
                    to
                    <ConnectionStatus :connection="row.item.new" />
                </div>
            </template>
            <template v-slot:cell(updatedBy)="row">
                <div>
                    {{ row.item.new.updatedBy }}
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
// API & Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Components
import ConnectionStatus from '@/views/setup/connection/ConnectionStatus';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import _ from 'lodash';

export default {
    name: 'connection-change-logs-details-view',
    components: {
        ConnectionStatus
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            changeLogs: [],
            fields: [
                'dateUpdated',
                'changes',
                'updatedBy',
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            connectionId: '',

            loggedUser: this.$store.getters.loggedUser,
            isSuperAdmin: this.$store.getters.isSuperAdmin,
        }
    },
    watch: {
        connectionId: async function (newVal) {
            if (newVal && newVal.length > 0) {
                await this.retrieveChangeLog(newVal);
            }
        }
    },
    async mounted() {
        this.connectionId = this.row.item.id;
        await this.retrieveChangeLog(this.connectionId);
    },
    methods: {
        async retrieveChangeLog(connectionId) {
            if (connectionId) {
                let param = {
                    collection: 'connections',
                    id: connectionId,
                    userId: !this.isSuperAdmin ? this.loggedUser.id : ''
                }

                let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
                this.changeLogs = Object.values(results);

                this.filterChangeLogs(this.changeLogs);
                this.totalRows = _.size(this.changeLogs);
            }
        },

        getNewItems(oldItems, newItems) {
            return _.difference(newItems, oldItems);
        },
        getRemovedItems(oldItems, newItems) {
            return _.difference(oldItems, newItems);
        },
        filterChangeLogs(changeLogs) {
            const filteredLogs = _.filter(changeLogs, (log) => {
                const oldLog = log.old ? log.old : {};
                const newLog = log.new ? log.new : {};

                // Notes
                const oldNotes = oldLog.notes ? oldLog.notes : '';
                const newNotes = newLog.notes ? newLog.notes : '';
                // Connections
                const addedConnections = this.getNewItems(oldLog.connections, newLog.connections).length;
                const removedConnections = this.getRemovedItems(oldLog.connections, newLog.connections).length;
                // Status
                const oldStatus = oldLog.isActive ? oldLog.isActive : 'false';
                const newStatus = newLog.isActive ? newLog.isActive : 'false';

                return oldNotes !== newNotes ||
                    addedConnections > 0 ||
                    removedConnections > 0 ||
                    this.hasConnectedStorageLocationChanged(log) ||
                    oldStatus !== newStatus;
            });
            this.changeLogs = filteredLogs;
        },

        hasValueChanged(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};

            let oldValue = oldLog[fieldName] ? oldLog[fieldName] : "-";
            let newValue = newLog[fieldName] ? newLog[fieldName] : "-";
            return oldValue !== newValue;
        },

        hasChangesInConnections(log) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};
            let oldConnections = oldLog.connections ? oldLog.connections : [];
            let newConnections = newLog.connections ? newLog.connections : [];

            let addedConnections = this.getNewItems(oldConnections, newConnections);
            let removedConnections = this.getRemovedItems(oldConnections, newConnections);

            return !_.isEmpty(addedConnections) || !_.isEmpty(removedConnections);
        },
        getAddedConnections(log) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};
            let oldConnections = oldLog.connections ? oldLog.connections : [];
            let newConnections = newLog.connections ? newLog.connections : [];

            let addedConnections = this.getNewItems(oldConnections, newConnections);

            if (!_.isEmpty(addedConnections)) {
                return addedConnections.join();
            }
            return "";
        },
        getRemovedConnections(log) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};
            let oldConnections = oldLog.connections ? oldLog.connections : [];
            let newConnections = newLog.connections ? newLog.connections : [];

            let removedConnections = this.getRemovedItems(oldConnections, newConnections);

            if (!_.isEmpty(removedConnections)) {
                return removedConnections.join();
            }
            return "";
        },

        hasConnectedStorageLocationChanged(log) {
            let oldLocations = log.old ? log.old.storageLocations : {};
            let newLocations = log.new ? log.new.storageLocations : {};

            const oldStorageLocations = this.getStorageLocationsArray(oldLocations);
            const newStorageLocations = this.getStorageLocationsArray(newLocations);

            return _.some(oldStorageLocations, (location, index) => {
                return location.isIncluded !== newStorageLocations[index].isIncluded;
            });
        },
        getStorageLocationsArray(locations) {
            if (!locations || typeof locations !== 'object') {
                console.error('Invalid locations:', locations);
                return [];
            }

            const includedStorageLocations = [];

            Object.keys(locations).forEach(storageLocationId => {
                const storageLocation = locations[storageLocationId];

                if (Object.keys(storageLocation).length > 0) {
                    includedStorageLocations.push(storageLocation);
                }
            });
            return includedStorageLocations;
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getOldValue(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let value = oldLog[fieldName] ? oldLog[fieldName] : "-";
            return value;
        },
        getNewValue(log, fieldName) {
            let newLog = log.new ? log.new : {};
            let value = newLog[fieldName] ? newLog[fieldName] : "-";
            return value;
        },
    }
}
</script>

<style scoped>
.new-value {
    color: green;
}

.old-value {
    color: red;
}

.changed-section {
    margin-bottom: 10px;
}
</style>